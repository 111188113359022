import React, { useState, useEffect } from 'react'
import axios from 'axios'

const isEmpty = (a) => {
  return a === null || a === undefined
}

const ContactPopup = ({ cc, buttonText, callback }) => {
  const [button, setButton] = useState('')
  const [form, setForm] = useState({})
  const [modal, setModal] = useState('')
  const [vh, setVh] = useState(200)

  let formRef = React.createRef()

  const handleChange = (key, event) => {
    event.persist()
    event.preventDefault()
    setForm((frm) => ({ ...frm, [key]: event.target.value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(event)
    console.log(form)
    setButton('Please wait')

    const buttonlabel = cc.heading

    const apibody = {
      buttonlabel: buttonlabel,
      form: form,
    }

    axios
      .post(
        'https://europe-west1-curated-stores.cloudfunctions.net/contactEmail?contact=lenaskidynamic',
        apibody
        // ,axiosConfig
      )
      .then((res) => {
        if (res.status === 200) {
          setModal('Sent')
          setButton('Sent')
          setTimeout(() => {
            callback(false)
          }, 4000)
        }
      })
      .catch((err) => {
        console.log('Message sending failed')
        setModal('Error sending message')
        console.warn(err)
      })
  }

  useEffect(() => {
    if (!isEmpty(formRef.current)) {
      setVh(formRef.current.clientHeight)
    }
  }, [])

  const grsgre = (i) => {
    return `grs${i} gre${i + 1}`
  }

  return (
    <div
      className="fixed z-9999 left-0 bottom-0 top-0 right-0 bg-black-70 grid pa5-ns pa3 w-100"
      id="outside"
      onClick={(event) => {
        if (event.target.id === 'outside') {
          console.log('outside')
          callback(false)
        }
      }}
    >
      {modal === '' ? null : (
        <div
          style={{ height: `${vh}px` }}
          className="tc z-max bg-white v-mid grs1 gre2 gcs1 gce2 br2"
        >
          <div className="v-mid f3 fw5 h-100 grid">
            <div className="self-center">Sent</div>
          </div>
        </div>
      )}
      <div
        ref={formRef}
        className={`${
          modal === '' ? 'bg-white' : 'bg-white-40'
        } dt gafr z-9999 br2 gar-maxc v-mid grs1 gre2 gcs1 gce2 mw-100 grid ph3`}
        id="inside"
      >
        {isEmpty(cc.heading) ? null : (
          <div className="fw6 pre ph6-ns ph0-m mt4">
            <div className="pv2 ph2-ns tl-ns tc f4 pre-wrap">{cc.heading}</div>
          </div>
        )}
        <form
          className="grid mt4-ns mt2 ph6-ns ph0-m ph2 grg3 hide-placeholder gar-maxc"
          id="insideform"
          onSubmit={handleSubmit}
        >
          {isEmpty(cc.fullName) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.fullName
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">First & Last Name:</div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.fullName
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="text"
                name="contact-name"
                placeholder="First & Last Name"
                id="contact-name"
                onChange={(e) => handleChange('name', e)}
                required
              />
            </>
          )}
          {isEmpty(cc.interest) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.interest
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">
                  Interest - Team or Individual Coaching:
                </div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.interest
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="text"
                name="contact-name"
                placeholder="Interest - Team or Individual Coaching"
                id="contact-name"
                onChange={(e) => handleChange('interest', e)}
                required
              />
            </>
          )}
          {isEmpty(cc.timezone) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.timezone
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">Timezone/Location:</div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.timezone
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="text"
                name="contact-name"
                placeholder="Timezone/Location"
                id="contact-name"
                onChange={(e) => handleChange('timezone', e)}
                required
              />
            </>
          )}
          {isEmpty(cc.email) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.email
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">Email:</div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.email
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="email"
                name="contact-email"
                placeholder="Email"
                id="contact-email"
                onChange={(e) => handleChange('email', e)}
                required
              />
            </>
          )}
          {isEmpty(cc.location) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.location
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">Location:</div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.location
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="text"
                name="contact-location"
                placeholder="Your Location"
                id="contact-location"
                onChange={(e) => handleChange('location', e)}
              />
            </>
          )}
          {isEmpty(cc.website) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.website
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl">Website Adress:</div>
              </label>
              <input
                className={`gcs2-ns gcs1 gce3 ${grsgre(
                  cc.website
                )} pv2 f5 pa3 b--ski-medium-turquoise input-reset ba lh-solid w-100`}
                type="text"
                name="contact-website"
                placeholder="Website adress"
                id="contact-website"
                onChange={(e) => handleChange('website', e)}
              />
            </>
          )}
          {isEmpty(cc.message) ? null : (
            <>
              <label
                className={`db-ns dn gcs1 gce2 ${grsgre(
                  cc.message
                )} grid content-center pv2 ph2-ns ph4`}
              >
                <div className="tl pb2">Message:</div>
              </label>
              <textarea
                className={`pv2 f5 gcs2-ns gcs1 gce3 pa3 input-reset ba b--ski-medium-turquoise lh-solid w-100 h4 ${grsgre(
                  cc.message
                )}`}
                type="text"
                name="contact-message"
                placeholder="Message"
                id="contact-message"
                onChange={(e) => handleChange('message', e)}
                required
              />
            </>
          )}
          <div className="pb4-ns pb2-m pb2 w-100 gcs1 gce3 grs6 gre7 grid">
            <input
              type="submit"
              value={button === '' ? buttonText : button}
              className="br2 pv1 ph3 ba b--ski-medium-turquoise grow glow mt2 db pointer gjs-end-ns white bg-ski-medium-turquoise"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactPopup
