import React, { useState, useEffect, Fragment } from 'react'
import PreviewCompatibleImage from './PreviewCompatibleImage'

let xStart = null
let yStart = null

const FlipBoxes = ({ boxes }) => {
  let w = null
  if (typeof window !== 'undefined') {
    w = window.innerWidth
  }
  let parts = w !== null && w !== undefined && w <= 480 ? 1 : 3
  let sets = Math.trunc((boxes.length - 1) / parts) + 1

  const [set, setSet] = useState(1)
  const [imgSet, setImgSet] = useState(boxes.filter((_, i) => i < parts))
  const [increment, setIncrement] = useState(1)
  const [prevIncrement, setPrevIncrement] = useState(1)
  const [interval, setInterval] = useState(0)

  useEffect(() => {
    resetLayout()
  }, [])

  useEffect(() => {
    // console.log(set)
    setImgSet(boxes.filter((_, i) => i < set * parts && i >= (set - 1) * parts))
  }, [set])

  const [hover, setHover] = useState(-1)

  useEffect(() => {
    if (increment === 1) {
      setTimeout(() => {
        setInterval(interval + 1)
        setPrevIncrement(1)
      }, 5000)
      if (increment === 1 && increment === prevIncrement) {
        setSet(set + 1 > sets ? 1 : set + 1)
      }
    }
    resetLayout()
  }, [interval, increment])

  const resetLayout = () => {
    if (typeof window !== 'undefined') {
      w = window.innerWidth
    }
    if (w <= 480) {
      parts = 1
    } else {
      parts = 3
    }
    sets = Math.trunc((boxes.length - 1) / parts) + 1
  }

  const oneImageBox = (oneimage, idx) => {
    return (
      <Fragment key={`box${idx}`}>
        <div
          className={`gcs${idx + 1}-ns gce${idx + 2}-ns gcs1 gce4 grid gc1 gr1`}
          onMouseEnter={(_) => {
            setHover(idx)
            setIncrement(0)
            setPrevIncrement(0)
          }}
          onMouseLeave={(_) => setHover(-1)}
          onClick={(_) => {
            if (w <= 480) {
              if (hover < 0) {
                setHover(idx)
                setIncrement(0)
                setPrevIncrement(0)
              } else {
                setHover(-1)
                setIncrement(1)
              }
            }
          }}
          onTouchStart={(e) => {
            xStart = e.touches[0].clientX
            yStart = e.touches[0].clientY
          }}
          onTouchMove={(e) => {
            if (!xStart || !yStart) {
              return
            }
            const xDiff = xStart - e.touches[0].clientX
            const yDiff = yStart - e.touches[0].clientY
            xStart = null
            yStart = null
            if (Math.abs(xDiff) >= Math.abs(yDiff)) {
              // horizontal
              const rightMove = xDiff < 0 ? 1 : -1
              const nextSet = set + rightMove
              const doSet = nextSet < 1 ? sets : nextSet > sets ? 1 : nextSet
              setSet(doSet)
            } else {
              // vertical
              setHover(-1)
              setIncrement(1)
            }
            // console.log(e)
          }}
        >
          <div className="gcs1 gce2 grs1 gre2 z-3">
            <PreviewCompatibleImage
              imageInfo={{
                image: oneimage.image,
                alt: oneimage.alt,
                imgClass: 'force-contain',
              }}
            />
          </div>
          {hover !== idx ? (
            <div className="gcs1 gce2 grs1 gre2 z-5 h-100 pa3 dn-ns grid justify-end">
              <div className="pa2 br-pill pulse bg-white-50 self-end f5">
                Click Here
              </div>
            </div>
          ) : (
            <div className="gcs1 gce2 grs1 gre2 z-5 bg-white-80 pv3 h-100 grid">
              <div className="self-center">
                <div className="w-100 tc lh-copy ph4 fw6 tracked pb3">
                  {oneimage.heading}
                </div>
                <div className="w-100 tc lh-copy ph4 fw3">{oneimage.body}</div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    )
  }

  return (
    <>
      <div
        className="grid gc3 gr1 w-100"
        onMouseEnter={(_) => {
          setIncrement(0)
          setPrevIncrement(0)
        }}
        onMouseLeave={(_) => setIncrement(1)}
      >
        {imgSet.map((oneimage, idx) => oneImageBox(oneimage, idx))}
        <div></div>
      </div>
    </>
  )
}

export default FlipBoxes
