import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import PreviewCompatibleImage from './../components/PreviewCompatibleImage'

import Layout from '../components/Layout'
import ImageRow from '../components/ImageRow'
import LogoLinkGrid from '../components/LogoLinks'
import FlipBoxes from '../components/FlipBoxes'

import podcastAngleSVG from '../img/podcast-angle-bg.svg'

import Markdown from 'markdown-to-jsx'
import ContactPopup from '../components/ContactPopup'

const SideSwipeHeading = ({ heading }) => (
  <div className="grid gc-stripy w-100 pv3 ph5 f4 ski-medium-silver tracked">
    <div className="gcs1 gce2 grs1 gre2 bb bw2 pl4 db-ns dn"></div>
    <div className="gcs2 gce3 grs1 gre2 ttu mh4-ns mh2 fw6">{heading}</div>
    <div className="gcs3 gce4 grs1 gre2 bb bw2 pr4 db-ns dn"></div>
  </div>
)

const ShowMore = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewMore: false,
    }
  }

  render() {
    const {
      publishing,
      packackingbrave,
      wellbeingeconomy,
      leadingconversations,
      talksandworkshops,
      events,
    } = this.props
    return (
      <>
        {/* // show more section  */}
        <div className="tc ttc fw5 f4 lh-copy pv4 grow ph0-ns ph4">
          <div
            onClick={(e) => {
              e.preventDefault()
              this.setState({ viewMore: !this.state.viewMore })
              console.log(this.state.viewMore)
            }}
            className="ski-dark-blue pointer link no-underline"
          >
            View More Coaching Projects, Events & Clients
          </div>
        </div>

        {!this.state.viewMore ? null : (
          <>
            {/* // silver line  */}
            <div className="w-100 pv3 ph7-ns ph5 f4 ski-verylight-blue tracked">
              <div className="bb bw2"></div>
            </div>

            {/* // portfolioquote  */}
            <div className="tc pt4-ns pt4 pb4 ph6-ns ph3-m ph3 ski-medium-green">
              <div className="f2-l f2-ns f3 tracked cursive">
                <Markdown>{publishing.quote}</Markdown>
              </div>
              <br className="dn-ns db"></br>
              <div>{publishing.quotecredit}</div>
            </div>

            {/* // Packaging & Bravespace  */}
            <div className="gafc grid tc pt3-ns pb2 pb0-ns ph6-l ph2-m ph3 gc3 gafr">
              <div className="gcs1 gce2-ns gce4 grs1 gre2 pa4 grid bg-ski-verylight-blue b--white bw2-ns bw3 bl-ns br-ns bt bb">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: packackingbrave.leftimage_pb,
                    alt: packackingbrave.leftalt,
                  }}
                />
                <div className="pt3-markup no-hr pt3-l pt0-m pt4 tc fw4 tracked f5 lh-copy ph3 self-end">
                  <Markdown>{packackingbrave.leftdescription}</Markdown>
                </div>
              </div>
              <div className="gcs2-ns gcs1 gce3-ns gce4 grs1-ns grs2 gre2-ns gre3 pa4 grid bg-ski-verylight-blue b--white bw2-ns bw3 bl-ns br-ns bt bb">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: packackingbrave.middleimage,
                    alt: packackingbrave.middlealt,
                  }}
                />
                <div className="pt3-markup no-hr pt4-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3 self-end">
                  <Markdown>{packackingbrave.middledescription}</Markdown>
                </div>
              </div>
              <div className="gcs3-ns gcs1 gce4 grs1-ns grs3 gre2-ns gre4 pa4 grid bg-ski-verylight-blue b--white bw2-ns bw3 bl-ns br-ns bt bb">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: packackingbrave.rightimage,
                    alt: packackingbrave.rightalt,
                  }}
                />
                <div className="pt3-markup no-hr pt3-l pt0-m pt4 tc fw4 tracked f5 lh-copy ph3 self-end">
                  <Markdown>{packackingbrave.rightdescription}</Markdown>
                </div>
              </div>
            </div>

            {/* // wellbeingeconomy  */}
            <div className="grid wellbeing-grid-ns mv5-l mv3-m ph6-ns ph3-m">
              <div className="gcs2-l gcs1 gce4 grs1 gre3 bg-ski-light-blue bg-ski-verylight-blue-ns"></div>
              <div className="gcs2-l gcs1 gce4 grs1 gre2 pl3-l tc fw4 f5 tracked-ns lh-copy pv4 ph5-l ph2">
                <div className="ttu fw5 f3-ns f4 tracked pt2 pb2-ns pb4">
                  {wellbeingeconomy.title}
                </div>
                <div className="ph4 pt3-markup-nottop2">
                  <Markdown>{wellbeingeconomy.topsection}</Markdown>
                </div>
              </div>
              <div className="db-ns dn gcs1 gce3 grs2 gre3 justify-self self-center-m pb4-l nt5-l">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: wellbeingeconomy.leftimage_wbe,
                    alt: wellbeingeconomy.leftalt,
                  }}
                />
              </div>
              <div className="gcs3-ns gcs1 gce4 grs2 gre3 tj fw4 f5 lh-copy pb4 ph5-ns ph3">
                <div className="db dn-ns ph4 pb4">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: wellbeingeconomy.wellbeingimage,
                    }}
                  />
                </div>
                <div className="pb4 tc fw5 f4 tracked-mega-ns">
                  {wellbeingeconomy.secondheading}
                </div>
                <div className="i tracked-ns pb3">
                  <Markdown>{wellbeingeconomy.bottomsection}</Markdown>
                </div>
              </div>
            </div>

            {/* // leading conversations  */}
            <div className="ph6-l ph3-m ph3">
              <div className="w-100 b--ski-medium-turquoise-ns bw1-ns bl-ns br-ns bt-ns bb-ns ph5-l ph2">
                <div className="tc ttu fw5 f3-ns f4 tracked mt0-ns mt2 pv4">
                  {leadingconversations.title}
                </div>
                <div className="fw6 pb3 tc tracked">
                  {leadingconversations.heading}
                </div>
                <div className="db-ns dn ph6 pt0-ns pt3">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: leadingconversations.image,
                      alt: leadingconversations.alt,
                    }}
                  />
                </div>
                <div className="dn-ns db ph2 pt1 pb2">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: leadingconversations.cellimage,
                      alt: leadingconversations.cellalt,
                    }}
                  />
                </div>
                <div className="ph5-l ph3-m pt4-ns pt2 pb4 fw4 f5 lh-copy tc lh-copy tracked-ns">
                  <div className="ph6-l ph3">
                    <Markdown>{leadingconversations.description}</Markdown>
                  </div>
                </div>
              </div>
            </div>

            {/* // Published  */}
            <div className="mt4-ns mt2 mb4 ph6-l ph0-m grid publishing-grid-ns">
              <div className="gcs2-ns gcs1 gce3-ns gce4 grs1 gre4 bg-ski-verylight-silver ph5-l ph0-m"></div>
              <div className="gcs2 gce3 grs1 gre2 tc ttu fw5 f3-ns f4 tracked pv4">
                {publishing.title}
              </div>
              <div className="gcs1 gce4 grs2 gre3 pb4 ph7-l ph6-m">
                <ImageRow imgs={publishing.images} padding={0.5} />
              </div>
            </div>

            {/* // talks & workshops  */}
            <div className="ph0-l ph0-m">
              <div className="w-100 gc3 grid pb3 bg-ski-verylight-silver ph5-l ph2">
                <div className="gcs1 gce4 grs1-ns grs1 gre2-ns gre2 mt0-ns tc mt2 pv4">
                  <div className="ttu fw5 f3-ns f4 tracked pb3">
                    {talksandworkshops.title}
                  </div>
                  <div className="fw6 tracked">
                    {talksandworkshops.talksheading}
                  </div>
                </div>

                <div className="db-ns dn gcs1 gce2-ns gce4 grs2-ns grs2 gre3-ns gre3 ph2 pt0-ns pt3">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: talksandworkshops.leftimage_taw,
                      alt: talksandworkshops.leftalt,
                    }}
                  />
                </div>
                <div className="gcs1 gcs2-ns gce4 gce3-ns grs2-ns grs2 gre3-ns gre3 ph2">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: talksandworkshops.middleimage,
                      alt: talksandworkshops.middlealt,
                    }}
                  />
                </div>
                <div className="db-ns dn gcs1 gcs3-ns gce4 grs2-ns grs4 gre3-ns gre5 pt0-ns pt3 ph2">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: talksandworkshops.rightimage,
                      alt: talksandworkshops.rightalt,
                    }}
                  />
                </div>
                <div className="gcs1 gce4 grs3-ns grs3 gre4-ns gre4 ph3-m pt4 pb4 fw4 f5 tc lh-copy tracked-ns">
                  <div className="ph6-l ph3">
                    {talksandworkshops.description}
                  </div>
                </div>
              </div>
            </div>

            {/* // Events new */}
            <div className="ph5-l">
              <div className="mt4 bg-ski-verylight-blue ph0-ns ph3">
                <div className="tc ph6-l ph5-m ph2 mt4-ns grid gc3-l gc2-m pv3">
                  <div className="gcs1 gce3-l gce2-m gce4 grs2-l grs1 gre3-l gre2 pr3-ns pb0-ns self-center pr5-l pb4 pt4">
                    <div className="ttu fw5 f4 tracked pb4">
                      {events.heading}
                    </div>
                    <div className="tracked ski-dark-silver pb3">
                      {events.subheading}
                    </div>
                    <div className="pb4-ns pb2 fw4 f5 tc lh-copy tracked-ns">
                      <Markdown>{events.description}</Markdown>
                    </div>
                  </div>
                  <div className="gcs3-l gcs2-m gcs1 gce4-l gce3-m gce3 grs2-l grs1-m gre2-m gre3-l grs3 gre4 grid">
                    <div className="self-center ph5-m">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: events.leftimage_e,
                          alt: events.leftalt,
                        }}
                        padding={0.6}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt4 bg-ski-verylight-blue ph0-ns ph3">
                <div className="tc ph5-l ph5-m ph2 grid gc3-l gc2-m pv3">
                  <div className="gcs1 gce3-l gce2-m gce4 grs2-l grs1 gre3-l gre2 pr5-ns pb0-ns self-center pb4 pt4">
                    <div className="ttu fw5 f4 tracked pb4">
                      {events.leftheading}
                    </div>
                    <div className="tracked ski-dark-silver pb3">
                      {events.leftsubheading}
                    </div>
                    <div className="pb4-ns pb2 fw4 f5 tc lh-copy tracked-ns">
                      <Markdown>{events.leftdescription}</Markdown>
                    </div>
                  </div>
                  <div className="gcs3-l gcs2-m gcs1 gce4 gce3-m grs2-l grs1-m gre2-m gre3-l grs3 gre4 grid">
                    <div className="self-center">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: events.rightimage,
                          alt: events.rightalt,
                        }}
                        padding={0.6}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    )
  }
}

export const PortfolioPageTemplate = ({
  portfolioprofilepicture,
  portfolioprimaryheadline,
  portfolioquote,
  talksandworkshops,
  talksandworkshopfeatures,
  dance,
  events,
  wellbeingeconomy,
  packackingbrave,
  leadingconversations,
  publishing,
  portfoliointro,
  review,
  clientlogos,
  testimonialsblurb,
  portfoliotestimonials,
  flipboxes,
}) => {
  const [popupConfig, setPopupConfig] = React.useState({})
  const [popupButton, setPopupButton] = React.useState('')

  let viewMore = false
  return (
    <>
      {JSON.stringify(popupConfig) === '{}' ? null : (
        <ContactPopup
          callback={(e) => {
            if (e === false) {
              setPopupConfig({})
            }
          }}
          cc={popupConfig}
          buttonText={popupButton}
        />
      )}
      <div>
        {/* profilepicture & primaryheadline */}
        <div className="w-100 gc-podcast grid portfolio-pt-ns">
          <div className="gcs2-ns gcs1 gce5 grs1 gre2 z-2 mb-1px">
            <PreviewCompatibleImage
              imageInfo={{
                image: portfolioprofilepicture.image,
                alt: portfolioprofilepicture.alt,
              }}
            />
          </div>
          <div
            className="db-ns dn gcs1 gce5 grs1 gre2 z-3 bg-100wh"
            style={{ backgroundImage: `url(${podcastAngleSVG})` }}
          ></div>
          <div className="gcs1 gce3-ns gce5 grs1-ns grs2 gre2-ns gre3 pt4 pt0-ns g-self-end lh-copy z-4">
            <div className="w-100 pl5-ns pr3-ns ph3 pb3-l">
              <div className="tr-l tc pb4 ski-dark-turquoise tracked f2-l f3-ns f3 ph3-l">
                What Sets Me Apart
              </div>
              <div className="tl-ns tc fw5 f4-l f5 ski-dark-silver tracked-mega pr3-m">
                {portfolioprimaryheadline.title}
              </div>
              <br />
              <div className="pb4 fw4 tl-ns tc f5 lh-copy tracked">
                {portfolioprimaryheadline.description}
              </div>
            </div>
          </div>
        </div>

        {/* // silver line  */}
        <div className="dn-ns db w-100 pv3 ph7-ns ph5 f4 ski-verylight-blue tracked">
          <div className="bb bw2"></div>
        </div>

        {/* // portfolioquote  */}
        <div className="tc pt4 pb4 ph6-ns ph3-m ph2 ski-medium-turquoise">
          <div className="f2-l f2-ns f3 tracked cursive">
            {portfolioquote.quote}
          </div>
          <div>{portfolioquote.quotecredit}</div>
        </div>

        {/* // portfolio intro  */}
        <div className="ph0-l">
          <div className="mt2 bg-ski-light-blue ph0-ns ph4">
            <div className="tc">
              <div className="fw5 f2-l f3 ph5-l tracked white-90 pt4-l pt4-m pt4 pb4">
                {portfoliointro.title}
              </div>
              <div className="fw4 f5 pb4 lh-copy ">
                <Markdown>{portfoliointro.description}</Markdown>
              </div>
            </div>
            <div className="dn-ns db ph4-ns ph0 pb3">
              <ImageRow
                imgs={[
                  {
                    image: portfoliointro.secondleftimage,
                    alt: portfoliointro.secondleftalt,
                  },
                  {
                    image: portfoliointro.middleimage,
                    alt: portfoliointro.middlealt,
                  },
                  {
                    image: portfoliointro.secondrightimage,
                    alt: portfoliointro.secondrightalt,
                  },
                ]}
                padding={0.6}
              />
            </div>
            <div className="db-ns dn ph4-ns ph0 pb3">
              <ImageRow
                imgs={[
                  {
                    image: portfoliointro.leftimage_pi,
                    alt: portfoliointro.leftalt,
                  },
                  {
                    image: portfoliointro.secondleftimage,
                    alt: portfoliointro.secondleftalt,
                  },
                  {
                    image: portfoliointro.middleimage,
                    alt: portfoliointro.middlealt,
                  },
                  {
                    image: portfoliointro.secondrightimage,
                    alt: portfoliointro.secondrightalt,
                  },
                  {
                    image: portfoliointro.rightimage,
                    alt: portfoliointro.rightalt,
                  },
                ]}
                padding={0.6}
              />
            </div>
          </div>
        </div>

        {/* review */}
        <div className="mh6-ns mh4-m ph4-ns ph2-m ph4 pv3 mb4">
          <div className="pt3-markup i no-hr tc fw4 f5 lh-copy pt4-ns pt2 pb3 ph5-ns ph3-m">
            <Markdown>{review.quote}</Markdown>
          </div>
          <div className="pt3-markup no-hr tc fw5 f5 lh-copy pb0-ns ph5-ns ph3">
            <Markdown>{review.attribution}</Markdown>
          </div>
        </div>

        {/* clientlogos */}
        <div className="w-100 mt2 pb4 ph7-ns ph3-m ph2">
          <SideSwipeHeading heading="Clients" />
          <LogoLinkGrid gridItems={clientlogos} />
        </div>

        {/* flipboxes <- weet nie waar dit moet kom nie */}
        <div className="w-100 mt2 pb4 ph6-ns ph3-m ph2">
          <FlipBoxes boxes={flipboxes} />
        </div>

        {/* // Testimonials  */}
        <div className="mh6-l mh4-m b--ski-medium-turquoise-ns bw1-ns bl-ns br-ns bt-ns bb-ns">
          {/* // Statement for testimonials */}
          <div className="cursive tc f2-ns f3 fw2 tracked ski-medium-turquoise ph5-l ph2-m ph4 pt4-ns pb4">
            {testimonialsblurb}
          </div>
          {/* // silver line  */}
          <div className="dn-ns db w-100 pv3 ph7-ns ph5 f4 ski-verylight-blue tracked">
            <div className="bb bw2"></div>
          </div>
          <div className="w-100 mt2 pb2 ph5-l ph2">
            <SideSwipeHeading heading="Testimonials" />
          </div>

          <div className="grid testimonial-portfolio-grid-l testimonial-portfolio-grid-alt-m ph6-l lh-copy w-100">
            {portfoliotestimonials.testimonial.map((onetestimonial, idx) => (
              <Fragment key={`test${idx}`}>
                <div
                  className={
                    'db-ns dn gcs' +
                    (idx % 2 === 0 ? 1 : 3) +
                    '-ns gcs' +
                    (idx % 2 === 0 ? 1 : 3) +
                    '-m gce' +
                    (idx % 2 === 0 ? 2 : 4) +
                    '-ns gce' +
                    (idx % 2 === 0 ? 3 : 5) +
                    '-m grs1 gre2 grs' +
                    (1 + idx * 2) +
                    '-l grs' +
                    (2 + idx * 2) +
                    '-m gre' +
                    (2 + idx * 2) +
                    '-l gre' +
                    (3 + idx * 2) +
                    '-m g-self-end mb4 mh6-m pb5-l'
                  }
                >
                  <div className="relative">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: onetestimonial.image,
                        alt: onetestimonial.alt,
                      }}
                    />
                    <div className="image-bottom-half-border absolute b--ski-medium-turquoise h-50 z-1 bg-transparent" />
                  </div>
                </div>
                <div
                  className={
                    'nd gcs' +
                    (idx % 2 === 1 ? 1 : 3) +
                    '-m gce' +
                    (idx % 2 === 1 ? 3 : 5) +
                    '-m grs' +
                    (2 + idx * 2) +
                    '-m gre' +
                    (3 + idx * 2) +
                    '-m g-self-end mb4 mh3-m h-100 grid-m ' +
                    (idx % 2 === 0 ? 'tl' : 'tr')
                  }
                >
                  <div className="self-center">
                    <div className="ph4 fw5 pt3">
                      {onetestimonial.attribution}
                    </div>
                    <div className="ph4 pt2 ski-medium-turquoise f5 fw5">
                      {onetestimonial.summary}
                      <div />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'gcs' +
                    (idx % 2 === 0 ? 2 : 1) +
                    '-l gcs1 gce' +
                    (idx % 2 === 0 ? 4 : 3) +
                    '-l gce5 grs' +
                    (1 + idx * 2) +
                    '-l gre' +
                    (2 + idx * 2) +
                    '-l ph3-ns pb4 ' +
                    (idx % 2 === 0 ? 'tl' : 'tr')
                  }
                >
                  <div className="ph4 pt3-markup no-hr i f5 pt3">
                    <Markdown>{onetestimonial.quote}</Markdown>
                  </div>
                  <div className="grid gc2">
                    <div
                      className={
                        'gcs' +
                        (idx % 2 === 0 ? 1 : 2) +
                        ' gce' +
                        (idx % 2 === 0 ? 2 : 3) +
                        ' grs1 gre2 grs' +
                        (1 + idx * 2) +
                        ' gre' +
                        (2 + idx * 2) +
                        ' gcs1-ns gce2-ns grs1-ns mt2 gre3-ns relative dn-ns dt ' +
                        (idx % 2 === 0 ? 'ml4' : 'mr4')
                      }
                    >
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: onetestimonial.image,
                          alt: onetestimonial.alt,
                        }}
                      />
                      <div className="image-bottom-half-border absolute b--ski-medium-turquoise h-50 z-1 bg-transparent" />
                    </div>
                    <div
                      className={
                        'dn-m gcs' +
                        (idx % 2 === 0 ? 2 : 1) +
                        ' gce' +
                        (idx % 2 === 0 ? 3 : 2) +
                        ' grs' +
                        (1 + idx * 2) +
                        ' gre' +
                        (2 + idx * 2) +
                        ' ph3-ns pb4 gcs1-ns gce3-ns grs1-ns gre2-ns ' +
                        (idx % 2 === 0 ? 'tl' : 'tr')
                      }
                    >
                      <div className="ph4 fw5 pt3">
                        {onetestimonial.attribution}
                      </div>
                      <div className="ph4 pt2 ski-medium-turquoise f5 fw5">
                        {onetestimonial.summary}
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        <div className="bb bw2 w6 center pb4 b--white"></div>

        {/* // Book a Consultation  */}
        <div className="tc ph5-ns ph4 bg-ski-medium-blue">
          <div className="grid gc2 pv4">
            <div className="gcs2-ns gcs1 gce3 gcs1-ns grs2 grs1-ns gre3 self-center pt3 pt0-ns">
              <button
                className="white-80 cursive f2-ns f3 fw2 tracked pb2 shadow-1 grow pointer link ph4 pv2 ba dt center self-center no-underline bg-ski-medium-turquoise-ns bg-ski-dark-blue b--ski-medium-turquoise-ns b--ski-dark-blue"
                onClick={(event) => {
                  event.preventDefault()
                  setPopupButton('Send')
                  setPopupConfig({
                    fullName: 1,
                    email: 2,
                    interest: 3,
                    timezone: 4,
                    message: 5,
                    heading: 'Book a Consultation',
                  })
                }}
              >
                Book a Consultation
              </button>
            </div>
            <div className="gcs1 gce2-ns gce3 grs1 gre2 self-center f5-m pb2 ph3-l ph4-m ph2 lh-copy">
              <span className="tracked f4-l fw6 ttc ski-dark-blue">
                Discover what's possible
              </span>
              <br />
              From Brand Strategy, Living your Greatness or Designing with
              Purpose
              <br />
              This
              <span className="fw6 tracked"> FREE 30 min Zoom </span>
              puts what matters back in centrestage
            </div>
            {/* <div className="gcs1 gce2-ns gce3 grs1-ns grs2 gre2-ns gre3 self-center f5-m pb2 ph3-ns ph2 ph4-m lh-copy">
            <span className="tracked">Discover what's possible over a </span>
            <br className="dn-l db"></br>
            <span className="fw6 tracked">FREE 30 min Zoom call</span>
            <div className="db-l dn-m dn">
              From Brand Strategy, Living your Greatness or Designing with
              Purpose
            </div>
            <div className="dn-l db-m db tracked">
              From Brand Strategy, Living your Greatness or Designing with
              Purpose
            </div>
            <span className="tracked fw6 ski-dark-blue">
              Put what matters back in centrestage
            </span>
          </div> */}
          </div>
        </div>

        <ShowMore
          publishing={publishing}
          packackingbrave={packackingbrave}
          wellbeingeconomy={wellbeingeconomy}
          leadingconversations={leadingconversations}
          talksandworkshops={talksandworkshops}
          events={events}
        />
      </div>
    </>
  )
}

PortfolioPageTemplate.propTypes = {
  portfolioprofilepicture: PropTypes.object,
  portfolioprimaryheadline: PropTypes.object,
  portfoliointroparagraph: PropTypes.func,
  portfolioquote: PropTypes.object,
  talksandworkshops: PropTypes.object,
  talksandworkshopfeatures: PropTypes.array,
  dance: PropTypes.object,
  events: PropTypes.object,
  wellbeingeconomy: PropTypes.object,
  packackingbrave: PropTypes.object,
  leadingconversations: PropTypes.object,
  publishing: PropTypes.object,
  portfoliointro: PropTypes.object,
  review: PropTypes.object,
  clientlogos: PropTypes.array,
  testimonialsblurb: PropTypes.string,
  portfoliotestimonials: PropTypes.object,
  flipboxes: PropTypes.array,
}

const PortfolioPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      seo={{
        title: 'Lena Ski - Coaching Portfolio',
        keywords: [
          `branddesign`,
          `purposework`,
          `workplaceculture`,
          `wellbeingeconomy`,
        ],
        description:
          'Business coaching program in South Africa. Developing sustainability-driven strategies and innovative ideas that will pioneer change',
      }}
      page="portfolio"
    >
      <PortfolioPageTemplate
        portfolioprofilepicture={frontmatter.portfolioprofilepicture}
        portfolioprimaryheadline={frontmatter.portfolioprimaryheadline}
        portfoliointroparagraph={frontmatter.portfoliointroparagraph}
        portfolioquote={frontmatter.portfolioquote}
        talksandworkshops={frontmatter.talksandworkshops}
        talksandworkshopfeatures={frontmatter.talksandworkshopfeatures}
        dance={frontmatter.dance}
        events={frontmatter.events}
        wellbeingeconomy={frontmatter.wellbeingeconomy}
        packackingbrave={frontmatter.packackingbrave}
        leadingconversations={frontmatter.leadingconversations}
        publishing={frontmatter.publishing}
        portfoliointro={frontmatter.portfoliointro}
        review={frontmatter.review}
        clientlogos={frontmatter.clientlogos}
        testimonialsblurb={frontmatter.testimonialsblurb}
        portfoliotestimonials={frontmatter.portfoliotestimonials}
        flipboxes={frontmatter.flipboxes}
      />
    </Layout>
  )
}

PortfolioPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PortfolioPage

export const pageQuery = graphql`
  query PortfolioPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/portfolio/" }) {
      frontmatter {
        portfolioprofilepicture {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
        portfolioprimaryheadline {
          description
          title
        }
        portfolioquote {
          quote
          quotecredit
        }
        talksandworkshops {
          title
          leftimage_taw {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          middleimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middlealt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          talksheading
          description
        }
        talksandworkshopfeatures {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          description
        }
        dance {
          title
          leftimage_d {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          heading
          subheading
          description
        }
        events {
          leftimage_e {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          heading
          subheading
          description
          leftheading
          leftsubheading
          leftdescription
        }
        wellbeingeconomy {
          title
          leftimage_wbe {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          wellbeingimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          topsection
          secondheading
          bottomsection
        }
        packackingbrave {
          leftimage_pb {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          leftdescription
          middleimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middlealt
          middledescription
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          rightdescription
        }
        leadingconversations {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          cellimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          cellalt
          heading
          description
        }
        publishing {
          title
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
              }
            }
            alt
          }
          quote
          quotecredit
        }
        portfoliointro {
          title
          description
          leftimage_pi {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          secondleftimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          secondleftalt
          middleimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middlealt
          secondrightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          secondrightalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
        }
        review {
          quote
          attribution
        }
        clientlogos {
          image {
            childImageSharp {
              fluid(maxWidth: 220, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          link
          name
        }
        flipboxes {
          image {
            childImageSharp {
              fluid(maxWidth: 320, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
          heading
          body
        }

        testimonialsblurb
        portfoliotestimonials {
          title
          testimonial {
            summary
            quote
            attribution
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  aspectRatio
                  src
                  srcSet
                  sizes
                  srcWebp
                  srcSetWebp
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`
