import React from 'react'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const ImageRow = ({ imgs, padding }) => (
  <div className="flex w-100">
    {imgs.map((oneimage, idx) => (
      oneimage.image === undefined ? null :
      <div
        key={"image" + idx}
        className={idx === 0 ? 'db-ns dn' + ` x` + idx : 'db' + ` x` + idx}
        style={{ flex: oneimage.image.childImageSharp.fluid.aspectRatio, paddingLeft: padding + 'rem', paddingRight: padding + 'rem' }}
      >
        <PreviewCompatibleImage
            imageInfo={{
              image: oneimage.image,
              alt: oneimage.alt,
              imgClass: "force-contain"
            }}
          />
      </div>
    ))}
  </div>
)

export default ImageRow
