import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const LogoLinkGrid = ({ gridItems }) => (
  <>
    <div className="grid flex-ns dn flex-auto w-100 mh-80vh">
      {gridItems.map((item, idx) => (
        <div
          key={idx}
          className={`ph3-ns ph2 db-ns h-100`}
          style={{
            flex:
              item.image === undefined
                ? 4 // only need this for the preview in the cms
                : item.image.childImageSharp === undefined
                ? 4 // only need this for the preview in the cms
                : item.image.childImageSharp.fluid.aspectRatio,
          }}
        >
          <PreviewCompatibleImage
            imageInfo={{
              image: item.image,
              alt: item.name,
              imgClass: 'dummy',
            }}
          />
        </div>
      ))}
    </div>
    <div className="dn-ns db w-100 mh-80vh ph3">
      <div className="grid flex dn-ns flex-auto w-100 ">
        {gridItems.filter((v,i) => ((i + 1)<=gridItems.length/2)).map((item, idx) => (
          <div
            key={idx}
            className={`ph3-ns ph2 db-ns h-100`}
            style={{
              flex:
                item.image === undefined
                  ? 4 // only need this for the preview in the cms
                  : item.image.childImageSharp === undefined
                  ? 4 // only need this for the preview in the cms
                  : item.image.childImageSharp.fluid.aspectRatio,
            }}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: item.image,
                alt: item.name,
                imgClass: 'dummy',
              }}
            />
          </div>
        ))}
      </div>
      <div className="grid flex dn-ns flex-auto w-100 mt4">
        {gridItems.filter((v,i) => ((i + 1)>gridItems.length/2)).map((item, idx) => (
          <div
            key={idx}
            className={`ph3-ns ph2 db-ns h-100`}
            style={{
              flex:
                item.image === undefined
                  ? 4 // only need this for the preview in the cms
                  : item.image.childImageSharp === undefined
                  ? 4 // only need this for the preview in the cms
                  : item.image.childImageSharp.fluid.aspectRatio,
            }}
          >
            <PreviewCompatibleImage
              imageInfo={{
                image: item.image,
                alt: item.name,
                imgClass: 'dummy',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  </>
)

LogoLinkGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default LogoLinkGrid
